import React from 'react';
import Layout from '../components/Layout';
import {useTranslation} from 'react-i18next';
import {Link} from 'gatsby';
import Seo from '../components/Seo';
import {withLocaleProvider} from '../locale/LocaleProvider';

export const SolutionsPage = () => {
  const {t, i18n} = useTranslation();
  return (
    <>
      <Seo
        lang={i18n.language}
        title={t('Solutions:seo:title')}
        description={t('Solutions:seo:description')}
        url={t('Solutions:seo:url')}
      />
      <Layout beforeFooter>

        <div className="bg-color-light">
          <div className="container content-sm">
            <div className="headline-center margin-bottom-40">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfGw3O5wwX2Z7Bl6Dqb7FwnEEDiz3YW1lfn3Sheuz0ZhuqeFQ/viewform?embedded=true" width="90%" height="2494" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </div> 
          </div>
        </div>
      </Layout>
    </>
  );
};

export default withLocaleProvider('it', SolutionsPage);